import React, { ReactNode } from 'react';

import BaseLayout from './components/layouts/BaseLayout';
import { createTheme } from '@material-ui/core/styles';

// More info on what you can update for the theme here: https://material-ui.com/customization/default-theme/#default-theme
const theme = createTheme({
  palette: {
    primary: {
      light: `#29b40b`,
      main: `#0c4400`,
      dark: `#082f00`,
    },
    type: `dark`,
    grey: {
      50: `#fafafa`,
      100: `#f5f5f5`,
      200: `#eeeeee`,
      300: `#e0e0e0`,
      400: `#bdbdbd`,
      500: `#9e9e9e`,
      600: `#757575`,
      700: `#616161`,
      800: `#424242`,
      900: `#212121`,
    },
    background: {
      default: `#161616`,
    },
  },
});

interface ProviderParams {
  element: ReactNode;
}

export const wrapWithProvider = (params: ProviderParams) => {
  return <BaseLayout theme={theme}>{params.element}</BaseLayout>;
};
